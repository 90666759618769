var script_url = "/assets/entenshop/";

function remove_order(id) {
    $("#search_startnummer .error_double").hide();
    $("#search_startnummer .error_max").hide();
    $(".payment_info").hide();
    $(".payment_button").hide();
    $("#payment_select .active").removeClass("active");

    $.ajax({
        type: "POST",
        url: script_url + "remove_order.php",
        data: {
            data: id,
        },
        success: function (response) {
            var jsonData = JSON.parse(response);

            if (jsonData.success == "1") {
                get_reserviert();
            }
        },
    });
}

function get_reserviert() {
    $("#order ul .empty").remove();
    $("#order ul *[data-id]").closest("li").remove();

    $.ajax({
        type: "POST",
        url: script_url + "get_reserviert.php",
        data: $(this).serialize(),
        success: function (response) {
            var jsonData = JSON.parse(response);

            if (jsonData.success == "1") {
                $.each(jsonData.data, function (key, value) {
                    var listitem =
                        '<li class="list-group-item d-flex justify-content-between align-items-center">' +
                        "Startnummer " +
                        value.startnummer +
                        '<a href="#" class="badge" data-id="' +
                        value.id +
                        '" data-startnummer="' +
                        value.startnummer +
                        '">' +
                        // '<i class="fa fa-2x fa-trash-alt"></i>' +
                        "</a>" +
                        '<input type="hidden" name="order[]" value="' +
                        value.id +
                        '">' +
                        "</li>";

                    if ($('#order ul [data-startnummer="' + value.startnummer + '"]').length == 0) {
                        $("#order ul").append(listitem);

                        $('#order a.badge[data-id="' + value.id + '"]').click(function (event) {
                            event.preventDefault();
                            remove_order($(this).data("id"));
                        });
                    }
                });
                $("#order .btn").show();
            } else {
                var listitem =
                    '<li class="empty list-group-item d-flex justify-content-between align-items-center">' + "keine Enten-Lose im Warenkorb" + "</li>";
                $("#order ul").append(listitem);
                $("#order .btn").hide();
            }
        },
    });
}

function currencyFormat(num) {
    num = num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "&nbsp;€";
    num = num.replace(".", ",");
    return num;
}

function togglePayment(element) {
    if ($(element).is(":checked")) {
        $(element).next().removeClass("far fa-square").addClass("fas fa-check");
    } else {
        $(element).next().removeClass("fas fa-check").addClass("far fa-square");
    }

    if ($("#agb input").is(":checked") && $("#dsgvo input").is(":checked")) {
        $(".card-header button").attr("disabled", false);
    } else {
        $(".card-header button").attr("disabled", true);
        $('.collapse.show').removeClass('show');
    }
}

$(document).ready(function () {
    get_reserviert();

    $('#search_order #resetBtn').on('click', function() {
        $('form#search_order #order_number').attr('value', '');
        $('form#search_order').submit();
    })

    $("#search_startnummer").submit(function (event) {
        $("#search_random input").val("");
        $("#search_startnummer .error_notavail").hide();
        $("#search_startnummer .error_double").hide();
        $("#search_startnummer .error_max").hide();

        if ($("#order ul *[data-id]").length >= 20) {
            $("#search_startnummer .error_max").show();
        } else if ($('#order ul [data-startnummer="' + $("#search_startnummer input").val() + '"]').length > 0) {
            $("#search_startnummer .error_double").show();
        } else if ($('#search_startnummer input[type="number"]').val().length > 0) {
            $.ajax({
                type: "POST",
                url: script_url + "search_startnummer.php",
                data: $(this).serialize(),
                success: function (response) {
                    var jsonData = JSON.parse(response);

                    if (jsonData.success == "1") {
                        get_reserviert();
                    } else if (jsonData.success == "0") {
                        $(".error_notavail").html("Startnummer nicht verfügbar. Die nächste freie Startnummer ist " + jsonData.next);
                        $(".error_notavail").show();
                        // console.log(jsonData.next);
                    }
                },
            });
        }
    });

    $("#search_random").submit(function (event) {
        // console.log("search_startnummer");
        event.preventDefault();

        $("#order ul .empty").remove();
        $("#order ul *[data-id]").closest("li").remove();

        $(".loading").removeClass("d-none");

        $("#search_startnummer input").val("");
        $("#search_startnummer .error_notavail").hide();
        $("#search_startnummer .error_double").hide();
        $("#search_startnummer .error_max").hide();

        if ($('#search_random input[type="number"]').val().length > 0) {
            $.ajax({
                type: "POST",
                url: script_url + "search_random.php",
                data: $(this).serialize(),
                success: function (response) {
                    var jsonData = JSON.parse(response);

                    if (jsonData.success == "1") {
                        get_reserviert();
                        $(".loading").addClass("d-none");
                    }
                },
            });
        }
    });

    $("#payment_select_bank").on("click", function () {
        var payment = $(this).data("payment");

        var anzahl = $("#order a[data-id]").length;
        var preis = 5;

        var order_total = anzahl * preis;
        $("#bank_info .order_total").html(currencyFormat(order_total));
        $("#order_total").val(order_total);
    });

    

    $("#agb input").change(function () {
        togglePayment(this);
    });

    $("#dsgvo :input").change(function () {
        togglePayment(this);
    });

    var $table = $("#order_table");
    $(function () {
        $("#toolbar")
            .find("select")
            .change(function () {
                $table.bootstrapTable("refreshOptions", {
                    exportDataType: $(this).val(),
                });
            });
    });

    var trBoldBlue = $("table");

    $(trBoldBlue).on("click", "tr", function () {
        $(this).toggleClass("bold-blue");
    });

    $(".add-amount").on("click", function () {
        var amount = $(this).data("amount");
        // console.log(amount);

        $("#anzahl").val(amount);
        $("#search_random").submit();
    });
});
