/*
 * Shuffle jQuery array of elements - see Fisher-Yates algorithm
 */
jQuery.fn.shuffle = function () {
    var j;
    for (var i = 0; i < this.length; i++) {
        j = Math.floor(Math.random() * this.length);
        $(this[i]).before($(this[j]));
    }
    return this;
};

var counter = function () {
    var a = 0;
    $(window).scroll(function () {
        if ($("#counter").length > 0) {
            var oTop = $("#counter").offset().top - window.innerHeight;
            if (a == 0 && $(window).scrollTop() > oTop) {
                $(".counter-value").each(function () {
                    var $this = $(this),
                        countTo = $this.attr("data-count");
                    $({
                        countNum: $this.text(),
                    }).animate(
                        {
                            countNum: countTo,
                        },
                        {
                            duration: 3000,
                            easing: "swing",
                            // specialEasing: {
                            //     width: "linear",
                            //     height: "easeOutBounce"
                            // },
                            step: function () {
                                $this.text(Math.floor(this.countNum));
                            },
                            complete: function () {
                                var number = parseFloat(
                                    this.countNum
                                ).toLocaleString(
                                    window.document.documentElement.lang
                                );
                                $this.text(number);
                                //alert('finished');
                            },
                        }
                    );
                });
                a = 1;
            }
        }
    });
};

function winner() {
    var winner = $("#winner").html().split(",");

    console.log(winner[1]);

    var place = 0;

    $("td.losnummer").each(function (index, element) {
        $(element).html(winner[place]);
        place = place + 1;
    });
}

$(document).ready(function () {
    if ($("#winner").length) {
        winner();
    }

    if ($("body").hasClass("entenrennen") && $("body").hasClass("index")) {
        counter();
    }

    $(".slick_sponsoren .slick-track").addClass("d-flex");
    $(".slick_sponsoren .slick-slide").addClass("align-self-center");

    $("#losnummer").keyup(function (event) {
        var losnummer = $("#losnummer").val();

        if (losnummer != "") {
            $("tr.trow, tr.thead").addClass("d-none");
            $("tr.verloren").removeClass("d-none");
            $("tr.gewonnen").addClass("d-none");

            var tableRow = $("td.losnummer")
                .filter(function () {
                    return $(this).text().trim() == losnummer;
                })
                .parent("tr.trow")
                .removeClass("d-none")
                .closest("table")
                .find("tr.thead")
                .removeClass("d-none")
                .closest("table")
                .find("tr.gewonnen")
                .removeClass("d-none")
                .closest("table")
                .find("tr.verloren")
                .addClass("d-none");
        } else {
            $("tr.trow, tr.thead").removeClass("d-none");
            $("tr.gewonnen").addClass("d-none");
            $("tr.verloren").addClass("d-none");
        }
    });

    // $('#unterstuetzer .unterstuetzer .row> .col-lg-4').shuffle().css('visibility', 'visible');
    // console.log('shuffle');

    $(".mitglied a").click(function (event) {
        event.preventDefault();
        window.open($(this).attr("href"));
    });
});

$(window).resize(function () {
    counter();
});
