$(document).ready(function () {
    var donation = 7500;
    var goal = 15000;

    // var level_percent = (((donation / goal) * 100)).toFixed();
    // console.log(level_percent);
    // $("#water_level #_clip1").css(
    //     "transform",
    //     "scaleY(" + level_percent / 100 + ")"
    // );

    $("#water_level #water_level").css(
        "clip-path",
        "inset(145px 0 0 0)"
    );

    // var top_position = ((367) * (level_percent / 100)).toFixed();
    // console.log(top_position);
    // $("#animation #top").css(
    //     "transform",
    //     "matrix(1,0,0,1,0," + top_position + ")"
    // );
});
